export enum TAG_TYPES {
  mainMovie = "bigTag",
  subMovie = "smallTag",
  cinemaHall = "programTag",
  movieVersion = "versionTag",
}

export enum MOVIE_VERSIONS {
  original = "MovieOriginal",
  dubbing = "MovieDubbing",
  subtitles = "MovieSubtitles",
}

export enum BADGE_COLORS {
  white = "white",
  outline = "blue",
  primary = "primary",
  secondary = "secondary",
  blue = "blueGradient",
  csfd = "csfd",
  gold = "gold",
  deluxe = "deluxe",
  club = "club",
  ladies = "ladies",
  atmos = "atmos",
}

export const COLOR_BY_TITLE = "colorByTile"; // eh?
export enum BADGE_COLOR_VARIANTS {
  white = "primary",
  outline = "secondary",
  primary = "primary",
  secondary = "primary",
  blue = "primary",
  csfd = "primary",
  gold = "primary",
  deluxe = "primary",
  club = "primary",
  ladies = "primary",
  atmos = "primary",
}

const CINEMA_HALLS_COLOR_VARIANTS = {
  withPrefix: {
    white: "program-blue",
    outline: "program-blue",
    primary: "program-blue",
    secondary: "program-blue",
    blue: "program-blue",
    csfd: "program-csfd",
    gold: "program-gold",
    deluxe: "program-deluxe",
    club: "program-blue",
    ladies: "program-ladies",
    atmos: "program-atmos",
  },
  withoutPrefix: {
    white: "blue",
    outline: "blue",
    primary: "blue",
    secondary: "blue",
    blue: "blue",
    csfd: "csfd",
    gold: "gold",
    deluxe: "deluxe",
    club: "club",
    ladies: "ladies",
    atmos: "atmos",
  },
};

export type Tag = {
  Code: string;
  Name: string;
};

type TagIcon = {
  url: string;
  alt: string;
};

export type CmsTag = {
  code: string;
  title: string;
  color: BADGE_COLOR_VARIANTS;
  category: TAG_TYPES;
  versionType: string;
  icon: TagIcon[];
};

export type SubMovie = Record<
  string | number,
  Record<string, any> & {
    order: number;
    categoryTags?: Array<{ tagCode: string }>;
    Properties?: Record<string | number, string>;
    screening?: Array<
      Record<string, any> & {
        Properties?: Tag[];
      }
    >;
  }
>;

export type MainMovie = Record<string, any> & {
  categoryTags?: Array<{ tagCode: string }>;
  subMovies?: SubMovie;
};

export const tagColorByTile = (
  tag: CmsTag | { color: BADGE_COLOR_VARIANTS | typeof COLOR_BY_TITLE },
) => tag.color === COLOR_BY_TITLE;

export const getSpecificTag = (code: string | number, tags: CmsTag[]) => {
  const tag = tags.find((tag) => Number(tag.code) === Number(code));
  return tag ?? null;
};

export const cinemaMoviesOrderSort = (a: any, b: any) => b?.order - a?.order;

export const getMovieVersionTags = (tags: CmsTag[]) => {
  return tags.filter((tag) => tag.category === TAG_TYPES.movieVersion);
};

export const getCinemaHallTags = (tags: CmsTag[]) => {
  return tags.filter((tag) => tag.category === TAG_TYPES.cinemaHall);
};

const mainMoviesTagsPriority = computed(() => {
  const bigTags: CmsTag[] = useState("TAGS_ENUM", () => []).value?.filter?.(
    (tag: CmsTag) => tag.category === TAG_TYPES.mainMovie,
  );

  const length = bigTags?.length;
  return {
    ...(bigTags ?? []).reduce(
      (acc, tag, index) => ({
        ...acc,
        [tag.code]: length - index,
      }),
      {},
    ),
    length,
  };
});

const subMoviesCinemaHallPriority = computed(() => {
  const programTags: CmsTag[] = useState("TAGS_ENUM", () => []).value?.filter?.(
    (tag: CmsTag) => tag.category === TAG_TYPES.cinemaHall,
  );
  const length = programTags?.length;
  return {
    ...(programTags ?? []).reduce(
      (acc, tag, index) => ({
        ...acc,
        [tag.code]: length - index + subMoviesSmallTagPriority.value.length,
      }),
      {},
    ),
    length,
  };
});

const subMoviesSmallTagPriority = computed(() => {
  const smallTags: CmsTag[] = useState("TAGS_ENUM", () => []).value?.filter?.(
    (tag: CmsTag) => tag.category === TAG_TYPES.subMovie,
  );
  const length = smallTags?.length;
  return {
    ...(smallTags ?? []).reduce(
      (acc, tag, index) => ({
        ...acc,
        [tag.code]: length - index,
      }),
      {},
    ),
    length,
  };
});

const subMoviesVersionTagPriority = computed(() => {
  const versionTags: CmsTag[] = useState("TAGS_ENUM", () => []).value?.filter?.(
    (tag: CmsTag) => tag.category === TAG_TYPES.movieVersion,
  );
  const length = versionTags?.length;
  return {
    ...(versionTags ?? []).reduce(
      (acc, tag, index) => ({
        ...acc,
        [tag.code]: length - index,
      }),
      {},
    ),
    length,
  };
});

const cachedMainMovieTags = new Map<MainMovie, number[]>();
export const getMainMovieTags = (mainMovie: MainMovie) => {
  if (cachedMainMovieTags.get(mainMovie)) {
    return cachedMainMovieTags.get(mainMovie) ?? [];
  }

  const codes: Set<number> = new Set();

  if (mainMovie.categoryTags) {
    mainMovie.categoryTags.forEach((category) => {
      codes.add(Number(category.tagCode));
    });
  }

  Object.values(mainMovie?.subMovies ?? {}).forEach((movie) => {
    if (movie.screening) {
      movie.screening.forEach((screening) => {
        if (screening?.Properties) {
          Object.values(screening.Properties).forEach((property) => {
            codes.add(Number(property.Code));
          });
        }
      });
    }

    if (movie.categoryTags) {
      movie.categoryTags.forEach((category) => {
        codes.add(Number(category.tagCode));
      });
    }
  });

  return (
    cachedMainMovieTags
      .set(
        mainMovie,
        Array.from(codes).sort(
          // If there are multiple bigTags, sort them also by priority
          (a, b) =>
            (mainMoviesTagsPriority.value[b] ?? 0) -
            (mainMoviesTagsPriority.value[a] ?? 0),
        ),
      )
      .get(mainMovie) ?? []
  );
};

const cachedSubMovieTags = new Map<SubMovie, number[]>();
export const getSubMovieTags = (subMovie: SubMovie) => {
  if (cachedSubMovieTags.get(subMovie)) {
    return cachedSubMovieTags.get(subMovie) ?? [];
  }

  const codes: Set<number> = new Set();

  if (subMovie.categoryTags) {
    subMovie.categoryTags.forEach((category) => {
      codes.add(Number(category.tagCode));
    });
  }

  if (subMovie.screening) {
    subMovie.screening.forEach((screening) => {
      if (screening?.Properties) {
        Object.values(screening.Properties).forEach((property) => {
          codes.add(Number(property.Code));
        });
      }
    });
  }

  return (
    cachedSubMovieTags
      .set(
        subMovie,
        Array.from(codes).sort(
          // If there are multiple bigTags, sort them also by priority
          (a, b) =>
            (mainMoviesTagsPriority.value[b] ?? 0) -
            (mainMoviesTagsPriority.value[a] ?? 0),
        ),
      )
      .get(subMovie) ?? []
  );
};

const cachedMainMoviePriority = new Map<MainMovie, number>();
export const mainMoviesByTagsSorter = (a: MainMovie, b: MainMovie) => {
  const aMovieTags = getMainMovieTags(a);

  const bMovieTags = getMainMovieTags(b);

  const highestAPriority: number =
    cachedMainMoviePriority.get(a) ??
    cachedMainMoviePriority
      .set(
        a,
        aMovieTags?.reduce?.(
          (acc, tag) =>
            acc +
            multiplyMovieOrderPriorityByTags(
              mainMoviesTagsPriority.value[tag],
              2,
            ),
          0,
        ) ?? 0,
      )
      .get(a) ??
    0;

  const highestBPriority: number =
    cachedMainMoviePriority.get(b) ??
    cachedMainMoviePriority
      .set(
        b,
        bMovieTags?.reduce?.(
          (acc, tag) =>
            acc +
            multiplyMovieOrderPriorityByTags(
              mainMoviesTagsPriority.value[tag],
              2,
            ),
          0,
        ) ?? 0,
      )
      .get(b) ??
    0;

  return highestBPriority - highestAPriority;
};

export const subMovieOrderIndexResolver = (movie: SubMovie) => {
  // The subMovieProgrammeTags crate object and transforms the array of categoryTags into an object with the tagCode as the key and 1 as the default value.
  const subMovieProgrammeTags = {
    ...(movie?.Properties ?? {}),
    ...(movie?.categoryTags?.map?.((tag) => tag.tagCode) ?? []).reduce(
      (acc, tag) => ({ ...acc, [tag]: 1 }),
      {},
    ),
  };
  const highestPriority = Object.keys(subMovieProgrammeTags).reduce(
    (acc, tag) =>
      acc +
      multiplyMovieOrderPriorityByTags(subMoviesCinemaHallPriority.value[tag]), // ProgrammeTags priority
    0,
  );
  const highestSmallTagPriority = Object.keys(subMovieProgrammeTags).reduce(
    (acc, tag) =>
      acc +
      multiplyMovieOrderPriorityByTags(subMoviesSmallTagPriority.value[tag]), // SmallTags priority
    0,
  );
  const highestVersionTagPriority = Object.keys(subMovieProgrammeTags).reduce(
    (acc, tag) =>
      acc +
      multiplyMovieOrderPriorityByTags(subMoviesVersionTagPriority.value[tag]), // VersionTags priority
    0,
  );

  return highestPriority + highestSmallTagPriority + highestVersionTagPriority;
};

export const tagsThemeBackgroundResolver = (
  movie: SubMovie,
  tags: CmsTag[],
  withoutPrefix = false,
): string => {
  const programTags: CmsTag[] = tags?.filter?.(
    (tag: CmsTag) => tag.category === TAG_TYPES.cinemaHall,
  );
  const COLOR_VARIANTS = withoutPrefix
    ? CINEMA_HALLS_COLOR_VARIANTS.withoutPrefix
    : CINEMA_HALLS_COLOR_VARIANTS.withPrefix;
  let colorVariant = COLOR_VARIANTS.club;
  if (movie.categoryTags) {
    movie.categoryTags.forEach((category) => {
      const tag = programTags.find((tag) => tag.code == category.tagCode);
      if (tag) {
        colorVariant = COLOR_VARIANTS?.[tag.color];
      }
    });
  }
  if (colorVariant !== COLOR_VARIANTS.club) {
    return colorVariant;
  }
  if (movie.Properties) {
    Object.keys(movie.Properties).forEach((property) => {
      const tag = programTags.find((tag) => tag.code == property);
      if (tag) {
        colorVariant = COLOR_VARIANTS?.[tag.color];
      }
    });
  }
  return colorVariant;
};

function multiplyMovieOrderPriorityByTags(tag, multiplier = 1) {
  return (tag ?? 0) * Math.pow(10, (tag ?? 0) * multiplier);
}
