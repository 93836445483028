type Anything = string | number | object | boolean | [];

// ! the `extends Anything` is required for implicitly typed arrays to work
// eg:
// const explicit: ('a' | null)[] = [].map((item) => (item ? `a` : null))
// const implicit = [].map((item) => (item ? `a` : null))

// const works = explicit.filter(isDef) // -> const works: "a"[]
// const doesNotWork = implicit.filter(isDef) // -> const doesNotWork: ("a" | null)[]

// TS will default to non-narrowing `filter` method typings if it can't recognize provided predicate as VALID type guard
// and without the "extend" the inferred type here would be "string" rather than "a" -> not compatible -> not VALID

// extending `any` won't change anything but we really want this fnc to be universal -> hence the `Anything` type
// I'm not sure how to type _anything_ properly so I moved the type to other system-wide custom types
// so it can be tweaked/extended in one place if it would get used elsewhere eventually

/**
 * returns true if the provided `value` is NOT `null` or `undefined`
 * in a way that also the TS understands
 * can be passed to `Array.filter` to clear that array of not-defined values
 * https://stackoverflow.com/a/57989288
 */
export const isDefined = <TValue extends Anything>(
  value?: Nullable<TValue>,
): value is Defined<TValue> => {
  return value !== null && value !== undefined;
};
