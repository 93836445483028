import {
  DEFAULT_CINEMA_LANGUAGE,
  DEFAULT_CINEMA_HANDLER,
  CINEMA_LANGUAGES,
  isValidCinemaLang,
  isValidCinemaSlug,
} from "~/utils/constants";
import { navigateTo } from "#imports";

import { useLastVisitedLocationCookie } from "~/composables/useLastVisitedLocationCookie";
import { shouldSkipRedirectCheck } from "~/utils/shouldSkipRedirectCheck";

export default defineNuxtRouteMiddleware((to) => {
  if (shouldSkipRedirectCheck(to.path)) {
    return;
  }

  const lastVisitedLang = useCookie("lastVisitedLang");
  const location = useLastVisitedLocationCookie();
  const cinemaSelectRequired = useCookie<boolean | undefined | null>(
    "cinemaSelectRequired",
  );

  const hasInvalidLanguage =
    !isValidCinemaLang(to.params.lang) ||
    !Object.values(CINEMA_LANGUAGES).some(
      (lang) => to.path?.startsWith?.(`/${lang}`),
    );

  const hasInvalidCinemaSlug =
    !to.params?.cinemaslug || !isValidCinemaSlug(to.params.cinemaslug);

  if (
    isValidCinemaLang(to.params.lang) === false &&
    isValidCinemaSlug(to.params.cinemaslug) === false &&
    location.has.value === false
  ) {
    cinemaSelectRequired.value = true;
  }

  if (
    (!isValidCinemaLang(to.params.lang) || !to.params?.cinemaslug) &&
    !Object.values(CINEMA_LANGUAGES).some(
      (lang) => to.path?.startsWith?.(`/${lang}`),
    ) &&
    to.path?.length < 1000
  ) {
    if (location.has.value) {
      return navigateTo(`${location.cookie.value}${to.fullPath}`);
    } else if (to.path !== "/") {
      return navigateTo(
        `/${
          lastVisitedLang.value ?? DEFAULT_CINEMA_LANGUAGE
        }/${DEFAULT_CINEMA_HANDLER}${to.fullPath}`,
      );
    }
  } else if (
    (hasInvalidLanguage || hasInvalidCinemaSlug) &&
    to.path?.length < 1000
  ) {
    if (location.has.value) {
      return navigateTo(location.cookie.value);
    } else if (to.path !== "/") {
      return navigateTo(
        `/${
          lastVisitedLang.value ?? DEFAULT_CINEMA_LANGUAGE
        }/${DEFAULT_CINEMA_HANDLER}`,
      );
    }
  }
});
