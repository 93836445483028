import { reactive } from "vue";
import tailwindBreakpoints from "~/tailwindBreakpoints";

export const initialDimension = {
  width: 320,
  height: 560,
};

export function shallowObjectValuesToInt(object) {
  const parsedObject = {};
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      parsedObject[key] = parseInt(object[key], 10);
    }
  }
  return parsedObject;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    {
      install: (app, options = {}) => {
        const throttler = {
          timeout: null,
          timestamp: Date.now(),
        };
        const opt = {
          ...{ breakpoints: { width: {}, height: {} }, delay: 100 },
          ...options,
        };
        const reactComponent = reactive({
          currentWidth: initialDimension.width,
          currentHeight: initialDimension.height,
          currentWidthBreakpoint: null,
          currentHeightBreakpoint: null,
        });

        if (process.client) {
          setTimeout(updateDimensions, 1);
        }

        const atLeastBreakpoint = (breakpoint, width = true) => {
          return (
            reactComponent[`current${width ? "Width" : "Height"}`] >=
            opt.breakpoints[width ? "width" : "height"][breakpoint]
          );
        };

        // provide methods for vue3 hooks
        app.provide("atLeastBreakpoint", atLeastBreakpoint);
        app.provide("breakpointOptions", reactComponent);

        app.mixin({
          computed: {
            currentWidth: () => reactComponent.currentWidth,
            currentHeight: () => reactComponent.currentHeight,
            currentWidthBreakpoint: () => reactComponent.currentWidthBreakpoint,
            currentHeightBreakpoint: () =>
              reactComponent.currentHeightBreakpoint,
          },
          methods: {
            atLeastBreakpoint,
            isBreakpoint(breakpoint, width = true) {
              return (
                breakpoint ===
                reactComponent[
                  width ? "currentWidthBreakpoint" : "currentHeightBreakpoint"
                ]
              );
            },
            isInBreakpoints(breakpoints, width = true) {
              return breakpoints.contains(
                reactComponent[
                  width ? "currentWidthBreakpoint" : "currentHeightBreakpoint"
                ],
              );
            },
          },
        });

        setTimeout(() => {
          if (process.client) {
            window?.addEventListener?.("resize", () => {
              const nowStamp = Date.now();
              if (throttler.timestamp + opt.delay < nowStamp) {
                throttler.timestamp = nowStamp;
                updateDimensions();
              } else {
                clearTimeout(throttler.timeout);
                throttler.timeout = setTimeout(updateDimensions, opt.delay);
              }
            });
          }
        }, 1);

        function findBreakpoint(breakpoints, currentSize) {
          for (const [breakpoint, size] of Object.entries(breakpoints).sort(
            (a, b) => (a[1] <= b[1] ? 1 : -1),
          )) {
            if (size < currentSize) {
              return breakpoint;
            }
          }
          return null;
        }

        function updateDimensions() {
          if (process.client) {
            reactComponent.currentWidth = window?.innerWidth;
            reactComponent.currentHeight = window?.innerHeight;
          }
          reactComponent.currentWidthBreakpoint = findBreakpoint(
            opt.breakpoints.width || {},
            reactComponent.currentWidth,
          );
          reactComponent.currentHeightBreakpoint = findBreakpoint(
            opt.breakpoints.height || {},
            reactComponent.currentHeight,
          );
        }
      },
    },
    {
      delay: 100,
      breakpoints: {
        width: shallowObjectValuesToInt(tailwindBreakpoints),
        height: { xs: 420 },
      },
    },
  );
});
