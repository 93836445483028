import Globals from "~/queries/Globals.gql";
import Navigations from "~/queries/Navigations.gql";
import Homepage from "~/queries/Homepage.gql";
import Page from "~/queries/Page.gql";
import PageUris from "~/queries/PageUris.gql";
import WeekMovies from "~/queries/WeekMovies.gql";
import ActionItems from "~/queries/ActionItems.gql";
import CinemaScreenings from "~/queries/CinemaScreenings.gql";
import CinemaScreeningsWithMovies from "~/queries/CinemaScreeningsWithMovies.gql";
import ListMainMovies from "~/queries/ListMainMovies.gql";
import ListSubMovies from "~/queries/ListSubMovies.gql";
import CinemaSpecificMovies from "~/queries/CinemaSpecificMovies.gql";
import AdvertisingItems from "~/queries/AdvertisingItems.gql";
import CinemaHalls from "~/queries/CinemaHalls.gql";
import CampaignDetail from "~/queries/CampaignDetail.gql";
import CinemaMovie from "~/queries/CinemaMovie.gql";
import MovieDetailComponents from "~/queries/MovieDetailComponents.gql";
import MovieSpecificScreenings from "~/queries/MovieSpecificScreenings.gql";
import ListMainMoviesMovieOrGroup from "~/queries/ListMainMoviesMovieOrGroup.gql";
import CommonContentComponents from "~/queries/CommonContentComponents.gql";
import ScreensaverPage from "~/queries/ScreensaverPage.gql";

import {
  useGlobalsSchemaQuery,
  useNavigationsSchemaQuery,
  useHomepageSchemaQuery,
  useOtherPageSchemaQuery,
  useAsyncComponentsSchemaQuery,
  useCinemaScreeningsSchemaQuery,
  useSpecificMoviesSchemaQuery,
  useCinemaHallsSchemaQuery,
  useSpecificMovieComponentsSchemaQuery,
  useGroupedSubMoviesSchemaQuery,
} from "~/composables/useFetchCraftClient";
import { getSiteHandle } from "~/utils/common";

const useFetchGlobalsData = (variables) => {
  return useGlobalsSchemaQuery(Globals, variables);
};

const useFetchNavigationsData = (variables) => {
  return useNavigationsSchemaQuery(Navigations, variables);
};

const useFetchHomepageData = () => {
  return useHomepageSchemaQuery(Homepage);
};

const useFetchOtherPageData = (variables) => {
  return useOtherPageSchemaQuery(Page, variables);
};

const useFetchCampaignDetailData = (variables) => {
  return useOtherPageSchemaQuery(CampaignDetail, variables);
};

const useFetchOtherPageUrisData = (variables) => {
  return useOtherPageSchemaQuery(PageUris, variables);
};

const useFetchWeekMoviesData = () => {
  return useAsyncComponentsSchemaQuery(WeekMovies);
};

const useFetchActionItemsData = () => {
  return useAsyncComponentsSchemaQuery(ActionItems);
};

const useFetchMainMoviesData = (options) => {
  return useAsyncComponentsSchemaQuery(ListMainMovies, options);
};

const useFetchSubMoviesData = (options) => {
  return useAsyncComponentsSchemaQuery(ListSubMovies, options);
};

const useFetchCommonContentData = (variables) => {
  return useAsyncComponentsSchemaQuery(CommonContentComponents, { variables });
};

const useFetchScreensaverData = (variables) => {
  return useAsyncComponentsSchemaQuery(ScreensaverPage, { variables });
};

const useFetchCinemaScreeningsData = (variables) => {
  return useCinemaScreeningsSchemaQuery(CinemaScreenings, variables);
};

const useFetchMovieSpecificScreeningsData = (variables) => {
  return useCinemaScreeningsSchemaQuery(MovieSpecificScreenings, variables);
};

const useFetchCinemaScreeningsWithMoviesData = (variables) => {
  return useCinemaScreeningsSchemaQuery(CinemaScreeningsWithMovies, variables);
};

const useFetchSpecificMoviesData = () => {
  return useSpecificMoviesSchemaQuery(CinemaSpecificMovies, {
    variables: {
      site: getSiteHandle(),
    },
  });
};

const useFetchSpecificMovieData = (variables) => {
  return useSpecificMoviesSchemaQuery(CinemaMovie, {
    variables,
  });
};

const useFetchAdvertisingData = () => {
  return useHomepageSchemaQuery(AdvertisingItems, {
    variables: {
      sites: [getSiteHandle()],
    },
  });
};

const useFetchCinemaHallsData = (variables) => {
  return useCinemaHallsSchemaQuery(CinemaHalls, variables);
};

const useFetchSpecificMovieComponentsData = (variables) => {
  return useSpecificMovieComponentsSchemaQuery(
    MovieDetailComponents,
    variables,
  );
};

const useFetchGroupedSubMoviesData = (variables) => {
  return useGroupedSubMoviesSchemaQuery(ListMainMoviesMovieOrGroup, variables);
};

export {
  useFetchGlobalsData,
  useFetchNavigationsData,
  useFetchHomepageData,
  useFetchCampaignDetailData,
  useFetchOtherPageData,
  useFetchOtherPageUrisData,
  useFetchWeekMoviesData,
  useFetchActionItemsData,
  useFetchMainMoviesData,
  useFetchSubMoviesData,
  useFetchGroupedSubMoviesData,
  useFetchCinemaScreeningsData,
  useFetchCinemaScreeningsWithMoviesData,
  useFetchSpecificMoviesData,
  useFetchSpecificMovieData,
  useFetchAdvertisingData,
  useFetchCinemaHallsData,
  useFetchSpecificMovieComponentsData,
  useFetchMovieSpecificScreeningsData,
  useFetchCommonContentData,
  useFetchScreensaverData,
};
