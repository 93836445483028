import { isPast, parseISO } from "date-fns";
export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig();
  try {
    const token = useCookie("token", { domain: config.public.COOKIES_DOMAIN });
    const tokenValidity = useCookie("token_validity", {
      domain: config.public.COOKIES_DOMAIN,
    });

    if (!token.value || isPast(parseISO(tokenValidity.value))) {
      const response = await getApiToken();
      token.value = response.token;
      tokenValidity.value = response.tokenValidity;
    }
  } catch (e) {
    console.error(e);
  }
});

const getApiToken = async () => {
  const config = useRuntimeConfig();
  try {
    const { data: response } = await useAPI("/api/token/create", {
      method: "POST",
      body: {
        api_key: config.public.API_KEY,
      },
    });

    return {
      token: response?.value?.token || "",
      tokenValidity: response?.value?.validity || "",
    };
  } catch (e) {
    console.error(e);
  }
};
