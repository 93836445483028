import { default as _91page_45slug_935oSiNbh5LcMeta } from "/srv/app/pages/[lang]/[cinema-slug]/[page-slug].vue?macro=true";
import { default as indexrT9m6S5rH0Meta } from "/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/[sub2-page]/[sub3-page]/index.vue?macro=true";
import { default as indexGPdudErGtUMeta } from "/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/[sub2-page]/index.vue?macro=true";
import { default as indexriH8JjgsTlMeta } from "/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/index.vue?macro=true";
import { default as indexOO9IbVUi8SMeta } from "/srv/app/pages/[lang]/[cinema-slug]/akce/[campaign-slug]/index.vue?macro=true";
import { default as indexEymr1s6fcUMeta } from "/srv/app/pages/[lang]/[cinema-slug]/filmy/index.vue?macro=true";
import { default as indexAolsaDgyH7Meta } from "/srv/app/pages/[lang]/[cinema-slug]/filmy/movie/[movie-slug]/index.vue?macro=true";
import { default as indexHa6j7268taMeta } from "/srv/app/pages/[lang]/[cinema-slug]/index.vue?macro=true";
import { default as indexTqv2re05WjMeta } from "/srv/app/pages/[lang]/[cinema-slug]/screensaver/index.vue?macro=true";
import { default as indexCQWZSGqi3tMeta } from "/srv/app/pages/[lang]/index.vue?macro=true";
import { default as indexNJYZETQXNRMeta } from "/srv/app/pages/index.vue?macro=true";
import { default as loginEjnmmqM9g3Meta } from "/srv/app/pages/login.vue?macro=true";
import { default as style_45guideGW7WmCxBZ0Meta } from "/srv/app/pages/style-guide.vue?macro=true";
import { default as component_45stubf6XY2ElhZwMeta } from "/srv/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubf6XY2ElhZw } from "/srv/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "lang-cinemaslug-pageslug",
    path: "/:lang()/:cinemaslug()/:pageslug()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/[page-slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-subpage-sub2page-sub3page",
    path: "/:lang()/:cinemaslug()/:subpage()/:sub2page()/:sub3page()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/[sub2-page]/[sub3-page]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-subpage-sub2page",
    path: "/:lang()/:cinemaslug()/:subpage()/:sub2page()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/[sub2-page]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-subpage",
    path: "/:lang()/:cinemaslug()/:subpage()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/[sub-page]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-akce-campaignslug",
    path: "/:lang()/:cinemaslug()/akce/:campaignslug()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/akce/[campaign-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-filmy",
    path: "/:lang()/:cinemaslug()/filmy",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/filmy/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-filmy-movie-movieslug",
    path: "/:lang()/:cinemaslug()/filmy/movie/:movieslug()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/filmy/movie/[movie-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug",
    path: "/:lang()/:cinemaslug()",
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cinemaslug-screensaver",
    path: "/:lang()/:cinemaslug()/screensaver",
    meta: indexTqv2re05WjMeta || {},
    component: () => import("/srv/app/pages/[lang]/[cinema-slug]/screensaver/index.vue").then(m => m.default || m)
  },
  {
    name: "lang",
    path: "/:lang()",
    component: () => import("/srv/app/pages/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/srv/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginEjnmmqM9g3Meta || {},
    component: () => import("/srv/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "style-guide",
    path: "/style-guide",
    component: () => import("/srv/app/pages/style-guide.vue").then(m => m.default || m)
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/boleslav-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/budejovice-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/budejoviceIgy-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/hradec-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/jihlava-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/liberec-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/olomouc-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/opava-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/ostrava-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/pardubice-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/plzen-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/praha5-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  },
  {
    name: component_45stubf6XY2ElhZwMeta?.name,
    path: "/praha9-sitemap.xml",
    component: component_45stubf6XY2ElhZw
  }
]