import { DEFAULT_CINEMA_HANDLER } from "~/utils/constants";
import { navigateTo } from "#imports";
import { useLastVisitedLocationCookie } from "~/composables/useLastVisitedLocationCookie";

export const useCinemaNavigation = () => {
  const cinemaSelectRequired = useCookie("cinemaSelectRequired");

  const isNavOpen = useState("isHeaderNavOpen", () => ({
    cinema: false,
    pages: false,
    login: false,
  }));

  const openCinemaNav = () => {
    isNavOpen.value.cinema = true;
  };

  const closeCinemaNav = () => {
    cinemaSelectRequired.value = null;
    isNavOpen.value.cinema = false;
  };

  const navigateToDefaultCinema = (callback = null) => {
    const lastVisitedLang = useCookie("lastVisitedLang");

    const location = useLastVisitedLocationCookie();

    location.setValue({
      lang: lastVisitedLang.value,
      cinema: DEFAULT_CINEMA_HANDLER,
    });

    navigateTo(location.cookie.value);

    if (callback) {
      callback();
    }
  };

  const hasSomeCinemaLocation = () => {
    const location = useLastVisitedLocationCookie();
    return location.has.value;
  };

  return {
    openCinemaNav,
    closeCinemaNav,
    hasSomeCinemaLocation,
    navigateToDefaultCinema,
  };
};
