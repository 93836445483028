import { onMounted, COMPONENT_VISIBILITY_ENUM, getSiteHandle } from "#imports";

import {
  useFetchGlobalsData,
  useFetchNavigationsData,
} from "~/composables/useFetchCraftData";

import { useAccountClient } from "~/composables/useAccountClient";

export const useGlobalLogic = async () => {
  const TAGS_ENUM = useState("TAGS_ENUM", () => []);
  const previewToken = useState("previewToken", () => null);
  const webSaleDomain = useState("webSaleDomain", () => null);

  const siteNavigation = useState("sitesNavigation", () => []);
  const logoLink = useState("logoLink", () => null);
  const topFixedNavigation = useState("topFixedNavigation", () => []);
  const callToActionNavigation = useState("callToActionNavigation", () => []);
  const mainNavigation = useState("mainNavigation", () => []);

  const footerNavigation = useState("footerNavigation", () => []);
  const tosItems = useState("tosItems", () => []);
  const footerBlock = useState("footerBlock", () => null);
  const footerMainNavigation = useState("footerMainNavigation", () => []);

  const globalKosmikData = useState("globalKosmikData", () => null);
  const headerLogo = useState("headerLogo", () => null);
  const footerLogo = useState("footerLogo", () => null);
  const socialLinks = useState("socialLinks", () => []);

  const user = useState("user", () => null);

  const gtm = useGtm();

  const favicon = ref(null);

  const route = useRoute();

  const globalsQueryVariables = reactive({
    site: getSiteHandle(),
    defaultSite: `default${route.params?.lang === "en" ? "En" : ""}`,
  });

  onMounted(async () => {
    if (previewToken?.value !== null) {
      await refreshGlobalsData();
      setGlobals();
      await refreshNavigations();
      setNavigations();
    }
  });

  const { getUser, isTokenValid } = useAccountClient();

  const { data: globalsData, refresh: refreshGlobalsData } =
    await useFetchGlobalsData(globalsQueryVariables);

  const setGlobals = () => {
    TAGS_ENUM.value = globalsData?.value?.movieTags ?? [];
    socialLinks.value = [];

    if (globalsData.value?.defaultSiteSettings?.facebook?.length) {
      socialLinks.value.push({
        type: "facebook",
        link: globalsData.value.defaultSiteSettings.facebook,
      });
    }

    if (globalsData.value?.defaultSiteSettings?.instagram?.length) {
      socialLinks.value.push({
        type: "instagram",
        link: globalsData.value.defaultSiteSettings.instagram,
      });
    }

    if (globalsData.value?.defaultSiteSettings?.youtube?.length) {
      socialLinks.value.push({
        type: "youtube",
        link: globalsData.value.defaultSiteSettings.youtube,
      });
    }

    if (globalsData.value?.defaultSiteSettings?.tikTok?.length) {
      socialLinks.value.push({
        type: "tikTok",
        link: globalsData.value.defaultSiteSettings.tikTok,
      });
    }

    if (globalsData.value?.defaultSiteSettings?.webSaleUrl) {
      webSaleDomain.value = globalsData.value.defaultSiteSettings.webSaleUrl;
    } else {
      webSaleDomain.value = null;
    }

    if (globalsData.value?.defaultSiteSettings?.favicon?.length) {
      favicon.value = globalsData.value.defaultSiteSettings.favicon[0];
    } else {
      favicon.value = null;
    }

    if (globalsData.value?.siteSettings?.mainLogo?.length) {
      headerLogo.value = globalsData.value.siteSettings.mainLogo[0];
    } else if (globalsData.value?.defaultSiteSettings?.mainLogo?.length) {
      headerLogo.value = globalsData.value.defaultSiteSettings.mainLogo[0];
    } else {
      headerLogo.value = null;
    }

    if (globalsData.value?.siteSettings?.footerLogo?.length) {
      footerLogo.value = globalsData.value.siteSettings.footerLogo[0];
    } else if (globalsData.value?.defaultSiteSettings?.footerLogo?.length) {
      footerLogo.value = globalsData.value.defaultSiteSettings.footerLogo[0];
    } else {
      footerLogo.value = null;
    }

    if (
      globalsData.value?.defaultFooterBlock &&
      (globalsData.value?.footerBlock?.visibility ===
        COMPONENT_VISIBILITY_ENUM.default ||
        !globalsData.value?.footerBlock?.visibility)
    ) {
      footerBlock.value = globalsData.value.defaultFooterBlock;
    } else if (globalsData.value?.footerBlock) {
      footerBlock.value = globalsData.value.footerBlock;
    } else {
      footerBlock.value = null;
    }

    globalKosmikData.value = {
      globalSet: globalsData.value.footerKosmik,
      defaultGlobalSet: globalsData.value.defaultFooterKosmik,
    };

    if (favicon.value) {
      useHead({
        link: [
          {
            rel: "shortcut icon",
            type: favicon.value.mimeType,
            href: favicon.value.url,
          },
          {
            rel: "icon",
            type: "image/x-icon",
            href: favicon.value.url,
          },
        ],
      });
    }
  };

  setGlobals();

  const { data: navigations, refresh: refreshNavigations } =
    await useFetchNavigationsData(globalsQueryVariables);

  const setNavigationState = (type, defaultType, state) => {
    if (navigations.value?.[type]?.length) {
      state.value = navigations.value[type];
    } else if (navigations.value?.[defaultType]?.length) {
      state.value = navigations.value[defaultType];
    } else {
      state.value = [];
    }
  };

  const setNavigations = () => {
    siteNavigation.value =
      navigations.value.cinemas
        ?.map?.((cinema) => ({
          ...cinema,
          nodeUri: cinema?.nodeUri?.startsWith?.("http")
            ? `/${cinema?.nodeUri?.split?.("/")?.slice?.(3).join?.("/")}`
            : `/${cinema?.nodeUri}`,
        }))
        ?.filter?.((cinema) => cinema.nodeUri?.length > 3) ?? [];

    if (navigations?.value?.logoLink?.length) {
      logoLink.value = navigations.value.logoLink[0];
    } else if (navigations?.value?.defaultLogoLink?.length) {
      logoLink.value = navigations.value.defaultLogoLink[0];
    } else {
      logoLink.value = null;
    }

    setNavigationState("fixedMenu", "defaultFixedMenu", topFixedNavigation);
    setNavigationState(
      "callToActionMenu",
      "defaultCallToActionMenu",
      callToActionNavigation,
    );
    setNavigationState("headerNav", "defaultHeaderNav", mainNavigation);
    setNavigationState("footerMenu", "defaultFooterMenu", footerNavigation);
    setNavigationState("footerTosMenu", "defaultFooterTosMenu", tosItems);
    setNavigationState(
      "footerMainNav",
      "defaultFooterMainNav",
      footerMainNavigation,
    );
  };

  setNavigations();

  if (globalsData.value?.defaultSiteSettings?.gtm && gtm) {
    gtm.enable(true);
  }

  const nuxtApp = useNuxtApp();

  nuxtApp.hook("page:loading:end", async () => {
    globalsQueryVariables.site = getSiteHandle();
    await refreshGlobalsData();
    setGlobals();
    await refreshNavigations();
    setNavigations();
    if (isTokenValid() === false) {
      user.value = await getUser();
    }
  });

  return {};
};
