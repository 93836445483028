import VueAppleLogin from "vue-apple-login";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const time = new Date();
    nuxtApp.vueApp.use(VueAppleLogin, {
      clientId: "cz.cinestar.websale",
      scope: "name email",
      redirectURI: "https://cinestar.cz/login",
      state: time.toString(),
      usePopup: true,
    });
  }
});
