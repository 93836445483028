export default defineNuxtRouteMiddleware(async (to) => {
  if (process.client) {
    if (to.query?.token !== undefined || to.hash?.includes("isPreview")) {
      const previewToken = useState("previewToken", () => to.query?.token);
      await nextTick(() => {
        previewToken.value = to.query?.token ?? "";
      });
    }
  }
});
