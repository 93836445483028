import { API_ERRORS } from "~/utils/constants";
import { useAsyncData, useToast } from "#imports";

export function useAccountClient() {
  const { $api } = useNuxtApp();
  const toast = useToast();
  const loading = ref(false);

  const config = useRuntimeConfig();

  const token = useCookie("token", { domain: config.public.COOKIES_DOMAIN });

  const localToken = useState("localToken", () => null);

  const getUser = async () => {
    if (!token.value) return null;
    try {
      const response = await useAsyncData("getUser", () =>
        $api("/api/account/get"),
      );
      setLocalTokenByCookie();
      return response?.data ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const login = async ({ email, password }) => {
    loading.value = true;
    try {
      const response = await $api("/api/account/authorize", {
        method: "POST",
        body: {
          email,
          password,
        },
      });
      if (
        response?.statuscode === 500 &&
        response?.code !== API_ERRORS.E_API_ALREADY_AUTHORIZED
      ) {
        throw new Error();
      }
      setLocalTokenByCookie();
      return response ?? null;
    } catch (e) {
      toast.add({
        title: "Chyba",
        text: "Něco se pokazilo. Zkuste to znovu později.",
        variant: "warning",
      });
      console.error(e);
      return null;
    } finally {
      loading.value = false;
    }
  };

  const loginByProvider = async ({ provider, provider_data }) => {
    if (loading.value) return;
    loading.value = true;
    try {
      const response = await $api("/api/account/authorize", {
        method: "POST",
        body: {
          provider,
          provider_data,
        },
      });
      if (
        response?.statuscode === 500 &&
        response?.code !== API_ERRORS.E_API_ALREADY_AUTHORIZED
      ) {
        throw new Error();
      }
      setLocalTokenByCookie();
      return response ?? null;
    } catch (e) {
      toast.add({
        title: "Chyba",
        text: "Něco se pokazilo. Zkuste to znovu později.",
        variant: "warning",
      });
      console.error(e);
      return null;
    } finally {
      loading.value = false;
    }
  };

  const logout = async () => {
    try {
      const response = await $api("/api/account/deauthorize");
      if (response?.statuscode === 500) {
        throw new Error();
      }
      setLocalTokenByCookie();
      return null;
    } catch (e) {
      toast.add({
        title: "Chyba",
        text: "Něco se pokazilo. Zkuste to znovu později.",
        variant: "warning",
      });
      console.error(e);
      return null;
    }
  };

  const _setLocalToken = (token) => {
    localToken.value = token;
  };

  const setLocalTokenByCookie = () => {
    _setLocalToken(token.value);
  };

  const isTokenValid = () => {
    if (!localToken.value || !token.value) return false;
    return localToken.value === token.value;
  };

  return {
    loading,
    getUser,
    login,
    loginByProvider,
    logout,
    setLocalTokenByCookie,
    isTokenValid,
  };
}
