import { HEADERS_NAMES } from "~/utils/constants";

export default defineNuxtPlugin(() => {
  const { public: config } = useRuntimeConfig();
  const defaultUrl = config.API_DOMAIN;
  const token = useCookie("token", { domain: config.COOKIES_DOMAIN });

  const api = $fetch.create({
    baseURL: defaultUrl,
    onRequest({ options }) {
      options.headers = new Headers(options.headers);
      if (token.value) {
        options.headers.set(HEADERS_NAMES.authToken, token.value);
      }
    },
  });

  return {
    provide: {
      api: api,
    },
  };
});
