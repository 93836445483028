import { kebabToCamelCase } from "~/utils/common";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const $route = useRoute();
  const isEnglish = $route?.params?.lang === "en";
  nuxtApp.provide("getTranslate", (key) => {
    const translations =
      config?.public?.translations[isEnglish ? "defaultEn" : "default"] ?? [];
    const specificTranslations =
      config?.public?.translations[
        `${kebabToCamelCase($route.params?.cinemaslug)}${isEnglish ? "En" : ""}`
      ] ?? [];
    return (
      specificTranslations.find((translate) => translate.key === key)
        ?.translation ||
      (translations.find((translate) => translate.key === key)?.translation ??
        "") ||
      key
    );
  });
});
