import { isDefined } from "~/utils/isDefined";
import {
  DEFAULT_CINEMA_HANDLER,
  DEFAULT_CINEMA_LANGUAGE,
  isValidCinemaLang,
  isValidCinemaSlug,
} from "#build/imports";

type TLocationParts = {
  lang: string;
  cinema: string;
};

export const useLastVisitedLocationCookie = () => {
  const lastVisitedLocation = useCookie("lastVisitedLocation");

  const has = computed(() => {
    return isDefined(lastVisitedLocation.value);
  });

  const lang = computed(() => {
    if (isDefined(lastVisitedLocation.value)) {
      return lastVisitedLocation.value.split("/")[1];
    }
    return null;
  });

  const cinema = computed(() => {
    if (isDefined(lastVisitedLocation.value)) {
      return lastVisitedLocation.value.split("/")[2];
    }
    return null;
  });

  const setValue = ({ lang, cinema }: TLocationParts) => {
    const cinemaSlug = isValidCinemaSlug(cinema)
      ? cinema
      : DEFAULT_CINEMA_HANDLER;

    const cinemaLang = isValidCinemaLang(lang) ? lang : DEFAULT_CINEMA_LANGUAGE;

    lastVisitedLocation.value = `/${cinemaLang}/${cinemaSlug}`;
  };

  const setRawValue = (value: string) => {
    lastVisitedLocation.value = value;
  };

  const clearValue = () => {
    lastVisitedLocation.value = null;
  };

  return {
    cookie: readonly(lastVisitedLocation),
    lang,
    cinema,
    has,
    setValue,
    setRawValue,
    clearValue,
  };
};
