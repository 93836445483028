import validate from "/srv/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45removed_45trailing_45slashes_45global from "/srv/app/middleware/01.removedTrailingSlashes.global.ts";
import _02_45redirect_45to_45valid_45cinema_45global from "/srv/app/middleware/02.redirectToValidCinema.global.ts";
import auth_45global from "/srv/app/middleware/auth.global.js";
import live_45preview_45token_45global from "/srv/app/middleware/livePreviewToken.global.ts";
import manifest_45route_45rule from "/srv/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45removed_45trailing_45slashes_45global,
  _02_45redirect_45to_45valid_45cinema_45global,
  auth_45global,
  live_45preview_45token_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}