export default {
  "<3xs": { max: "319px" },
  "3xs": "320px",
  "<2xs": { max: "359px" },
  "2xs": "360px",
  "<xs": { max: "449px" },
  xs: "450px",
  "<sm": { max: "639px" },
  sm: "640px",
  "<md": { max: "767px" },
  md: "768px",
  "<lg": { max: "960px" },
  lg: "961px",
  "<xl": { max: "1279px" },
  xl: "1280px",
  "<2xl": { max: "1439px" },
  "2xl": "1440px",
  "<3xl": { max: "1680px" },
  "3xl": "1681px",
  "<4xl": { max: "1919px" },
  "4xl": "1920px",
};
