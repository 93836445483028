import { createHttpLink, from } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const { $apollo } = nuxtApp;
    const CMS_DOMAIN = nuxtApp.$config?.public?.CMS_DOMAIN;

    // trigger the error hook on an error
    const errorLink = onError((err) => {
      nuxtApp.callHook("apollo:error", err);
    });

    // Default httpLink (main communication for apollo)
    const httpLink = createHttpLink({
      uri: () => {
        const previewToken = useState("previewToken", () => null);
        const token =
          // eslint-disable-next-line
            new URLSearchParams(window?.location?.search)?.get?.("token") ??
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          previewToken.value;
        return `${CMS_DOMAIN}/api${token ? `?token=${token}` : ""}`;
      },
    });
    $apollo.defaultClient.setLink(from([errorLink, httpLink]));
  }
});
