import { getSiteHandle, useAsyncQuery } from "#imports";

const CRAFT_SEGMENT_TOKENS = {
  GLOBALS: "EkHFCQMLh8B5xiGQNxRrU2D3wYUzUIdi",
  NAVIGATIONS: "8lsFbMA7ALGv5grad5tXJpFdR3I4HA4K", // TODO
  HOMEPAGE: "pi0KwK7kWw6Sy4SlJ7UQaroPvzAT1BaI",
  OTHER_PAGES: "6J1TrNB0pWbb_ZBLnQyGaDkdE356E3Z7",
  ASYNC_COMPONENTS: "xNzScua-E3LE3kGRyYKr0MGxGASM6mwX",
  CINEMA_SCREENINGS: "jDwfZ1hXWbrzYPkc7ZAb21RJOPGKvaIn", // TODO
  SPECIFIC_MOVIES: "fVI-cPmlx0isZQQupccDwEMf0Q-QMrKB", // TODO
  CINEMA_HALS: "gZ6_62gIDqVrXqQ-CFsbFsA77ttj-qY0", // TODO
  CINEMA_MOVIE_DETAIL_COMPONENTS: "ADySJps2Of9VzV_45VDgogH0y8rCdjJU",
  MAIN_MOVIES: "TXtN95FT7GTRFLp9oYwlm7wHSXccRNvo",
};

const useFetchCraftClient = (query, headers = {}, options = {}) => {
  const route = useRoute();

  const DEFAULT_OPTIONS = {
    variables: {
      site: getSiteHandle(),
      defaultSite: `default${route.params?.lang === "en" ? "En" : ""}`,
    },
    cache: false,
  };

  return useAsyncQuery({
    query,
    ...DEFAULT_OPTIONS,
    context: {
      headers: { ...headers },
    },
    ...options,
  });
};

const useGlobalsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.GLOBALS}`,
    },
    { variables },
  );
};

const useNavigationsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.NAVIGATIONS}`,
    },
    { variables },
  );
};

const useHomepageSchemaQuery = (query, options = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.HOMEPAGE}`,
    },
    options,
  );
};

const useOtherPageSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.OTHER_PAGES}`,
    },
    { variables },
  );
};

const useAsyncComponentsSchemaQuery = (query, options = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.ASYNC_COMPONENTS}`,
    },
    options,
  );
};

const useCinemaScreeningsSchemaQuery = (query, variables) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_SCREENINGS}`,
    },
    { variables },
  );
};

const useSpecificMoviesSchemaQuery = (query, options) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.SPECIFIC_MOVIES}`,
    },
    options,
  );
};

const useSpecificMovieComponentsSchemaQuery = (query, variables) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_MOVIE_DETAIL_COMPONENTS}`,
    },
    { variables },
  );
};

const useCinemaHallsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_HALS}`,
    },
    { variables },
  );
};

const useGroupedSubMoviesSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.MAIN_MOVIES}`,
    },
    { variables },
  );
};

export {
  useGlobalsSchemaQuery,
  useNavigationsSchemaQuery,
  useHomepageSchemaQuery,
  useOtherPageSchemaQuery,
  useAsyncComponentsSchemaQuery,
  useCinemaScreeningsSchemaQuery,
  useSpecificMoviesSchemaQuery,
  useSpecificMovieComponentsSchemaQuery,
  useCinemaHallsSchemaQuery,
  useGroupedSubMoviesSchemaQuery,
};
