import { traverseDomToAddNbsp } from "~/utils/common";

export default defineNuxtPlugin((nuxtApp) => {
  const resolveRows = (el: any, options: any) => {
    const colsCount = options.value;
    const childrenCount = el.children?.length;
    el.style.gridTemplateRows = `repeat(${Math.ceil(
      childrenCount / colsCount,
    )}, minmax(0, 1fr))`;
  };
  nuxtApp.vueApp.directive("grid-cols-to-rows", {
    updated: resolveRows,
    mounted: resolveRows,
  });

  nuxtApp.vueApp.directive("addnbsp", {
    updated: traverseDomToAddNbsp,
    mounted: traverseDomToAddNbsp,
  });

  const addTargetBlanks = (el: any) => {
    el?.querySelectorAll("a:not([target='_blank'])")?.forEach((a: any) => {
      a.target = "_blank";
    });
  };
  nuxtApp.vueApp.directive("links-to-new-window", {
    updated: addTargetBlanks,
    mounted: addTargetBlanks,
  });
});
